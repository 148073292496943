// 考试报名信息
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from "@/libs/axios";

// 考试报名信息列表
export const getExamRecord = params => {
  return getRequest("/yethan/register/examRecord/listPage", params);
};
// 添加
export const addExamRecord = params => {
  return postRequest("/yethan/register/examRecord", params);
};
// 修改
export const editExamRecord = params => {
  return putRequest("/yethan/register/examRecord", params);
};
// 删除
export function deleteExamRecord(sid) {
  return deleteRequest("/yethan/register/examRecord/" + sid);
}

// 考试报名信息详情
export function getExamDetails(ksbmbh) {
  return getRequest("/yethan/register/examRecord/" + ksbmbh);
}


// 单字段修改
export function editFiled(sid, key, obj) {
  putRequest("/yethan/register/examRecord/" + sid + "/" + key, obj)
}

// 考试列表下拉
export const getExamEasy = params => {
  return getRequest("/yethan/register/examRecord/easylist", params, false);
};

// 存储切换考试信息
export const setNowExam = function(ksbmbh) {
  return getRequest(`/yethan/register/examRecord/setNowExam/${ksbmbh}`);
};

// 查询切换考试信息
export const getNowExam = params => {
  return getRequest("/yethan/register/examRecord/getNowExam", params, false);
};
// 查询当前权限
export const getNowExamHandle = params => {
  return getRequest("/yethan/register/examRecord/getNowExamHandle", params, false);
};

// 考区查询
export const getAreaList = params => {
  return getRequest("/yethan/register/examArea/list", params);
}
// 添加考区
export const addAreaList = params => {
  return postRequest("/yethan/register/examArea/", params);
}
// 修改
export const editAreaList = params => {
  return putRequest("/yethan/register/examArea/", params);
};
// 删除
export function deleteAreaList(sid) {
  return deleteRequest("/yethan/register/examArea/" + sid);
}

// 报名字段列表
export const getField = params => {
  return getRequest("/yethan/register/field/list", params, false);
}

// 打印字段模板列表
export const getMould = params => {
  return getRequest("/yethan/print/printTemplate/easyList", params);
}
// 刷新缓存
export const refresh = function(ksnf) {
  return getRequest(`/yethan/register/examRecord/updateRedisList/${ksnf}`)
}

// 报名表数据清库
export const clearData = ksbmbh => {
  return postRequest(`/yethan/registerUser/clearRegisterUser?ksbmbh=${ksbmbh}`);
};


export const getNowExamSync = async function() {
  return await getNowExam()
}
export const getExamEasyNow = params => {
  return getRequest("/yethan/register/examRecord/easylist/now", params, false);
}
export const importExam = params => {
  return getRequest("/yethan/register/examRecord/importExam", params);
}
