<template>
  <div>
    <yzFormItem :innerElement="item" v-model="form" @change="getForm"></yzFormItem>
  </div>
</template>

<script>
import yzFormItem from "@/components/form/yzFormItem.vue";
export default {
  name: "dynamic-form.vue",
  components:{
    yzFormItem,
  },
  data(){
    return {
      item: {
        bound:"aaa",
        type:"local1",
        dict: "dict_xzqh",
        name:"11",
        glzdb:true,

      },
      form: { "ssdm1": "12", "ssmc1": "天津市", "djsdm1": "01", "djsmc1": "市辖区", "qxdm1": "120103", "qxmc1": "天津市|市辖区|河西区" },
    }
  },
  methods:{
    getForm(e){
      this.form = e
      this.$forceUpdate()
      console.log(e)
    }
  }
}
</script>

<style scoped>

</style>